import React from 'react';
// import { Link } from 'gatsby'

import Layout from '../components/Layout';
import H1 from '../components/H1';
import H2 from '../components/H2';

const Contact = () => (
  <Layout>
    <H1>Kontakt</H1>
    <H2>Gospodarstwo Pasieczne BONIKA</H2>
    <p>
      Leszek Bodzioch
      <br />
      ul. Westerplatte 8 / 12
      <br />
      33-100 Tarnów
      <br />
      tel. <a href='tel:+48509212372'>509-212-372</a>,{' '}
      <a href='tel:+48509991728'>509-991-728</a>
      <br />
      email: <a href='mailto:lbodzioch@interia.pl'>lbodzioch@interia.pl</a>
    </p>
  </Layout>
);

export default Contact;
